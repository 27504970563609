import React from "react";
import { classNames } from "~/lib";

type Props = {
  noMargin?: boolean;
  tiny?: boolean;
  className?: string;
};

/**
 * A small spinner that can be placed inside a button.
 * @constructor
 */
const ButtonSpinner = ({ noMargin, tiny, className }: Props) => {
  return (
    <div
      className={classNames(
        noMargin ? "" : "mr-2",
        tiny ? "h-3 w-3" : "h-4 w-4",
        "inline-block animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
        className ? className : ""
      )}
    />
  );
};

export default ButtonSpinner;
